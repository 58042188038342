.App {
  text-align: center;
  font-family: bahnschrift;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Navbar */
.navbar{
  margin-right: "20px";
}

.modal-content {
  word-wrap: break-word;
  overflow-wrap: break-word; 
  white-space: pre-wrap; 
}

.modal-content a {
  word-wrap: break-word; 
  overflow-wrap: break-word;
  color: #ff4e00;
}

/* Home.jsx */
.homemain{
  background: url(../public/Assets/homee.gif);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 15%;
  padding-bottom: 15%;
}

.links{
  color: white;
}

.mainheading{
  font-size: 90px;
  color: white;
  font-weight: bolder;
}

.normalheading{
  font-size: 70px;
  color: white;
  font-weight: normal;
}

.paragraph{
  color: white;
}

.navbaricons{
  border:"2px solid white";
  border-radius:"50%";
  width:"40px";
  height:"40px";
  display:"flex";
  align-items:"center";
  justify-content:"center";
  margin-right:"10px";
}

.my-card {
  background-color: #1a2229 !important;
  padding: 10px;
}

.my-card:hover{
  background-color: #1b60f2 !important;
  color: white;
}


/* Register */
.register{
  background: url(../public/Assets/homee.gif);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.register-card{
  background-color: #1a2229 !important;
  max-width: 600px;
  width: 100%;
}

.inputs{
  border-radius: 0;
}

/* About */

.head{
  padding: 70px;
}

.image-container {
  position: relative;
  width: 100%;
  height: 650px;
  background-image: url(../public/Assets/homeimg.png); 
  background-size: cover;
  background-position: center;
  box-shadow: 0 4px 8px rgba(0, 0, 1, 0.1);
  overflow: hidden; 
  margin-top: 3%;
}

.image-text h1{
  content: "";
  position: absolute;
  top: 45%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  text-align: center; 
  color: #ff4e00; 
  font-size: 50px;
  font-weight: 900; 
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  text-shadow: none !important;
}

.image-text p{
  content: "";
  position: absolute;
  top: 55%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  text-align: center; 
  color: black; 
  font-size: 20px;
  font-weight: 900; 
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  text-shadow: none !important;
}

.star-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  fill: gold;
}

/* Responsiveness */
/* 800px */
@media screen and (max-width: 900px) {
  .image-text h1{
    top: 45%; 
    left: 50%; 
    font-size: 40px;
  }
  
  .image-text p{
    top: 56%; 
    font-size: 20px;
  }
}

/* 680px */
@media screen and (max-width: 680px) {
  .image-text h1{
    top: 45%; 
    left: 50%; 
    font-size: 30px;
  }
  
  .image-text p{
    top: 68%; 
    font-size: 20px;
  }

  .image-container {
    height: 400px;
  }
}

/* Chatbar */
.chat-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.chat-header {
  text-align: center;
  margin-bottom: 20px;
}

.message-container {
  max-height: 300px;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.message {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
}

.sent {
  background-color: #007bff;
  color: #fff;
  align-self: flex-end;
}

.received {
  background-color: #f8f9fa;
  color: #212529;
  align-self: flex-start;
}

.message-input {
  display: flex;
  align-items: center;
}

.message-input input {
  flex-grow: 1;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
}

.message-input button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
}

.white-switch .switch {
  background-color: white !important; 
}

.white-switch .switch:after {
  background-color: #6c757d !important; 
}

.subadminform{
  height: 100vh  !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

input,label{
  color: white !important;
}

button{
  background-color: #FF4E00 !important;
}

.viewerstoggle{
  background-color: #0000 !important;
}

.modalRegister{
  margin-top: 83px !important;
}



.assignjobmodal{
  margin-top: 100px !important;

}

.css-13cymwt-control{
  background-color: black !important;
}
.css-13cymwt-control:focus {
  background-color: black !important;
}

.css-13cymwt-control:active {
  background-color: black !important;
}

.select__control--is-focused{
  background-color: black !important;
}

.select__option
{
  background-color: black !important;
  border: 1px solid white !important;

}

.select__menu{
  background-color: black !important;
  border: 1px solid white !important;

}

.select__placeholder{

  text-align: left !important;
  color: white !important;
  cursor: none !important;
}

input[type="file"]::file-selector-button {
  color: white !important;  
}


.imagesinside  {

  width: 98%;
}



.crossicon{
  position: absolute;
  top: 0px;
  right: 10px;
  z-index: 100;
  cursor: pointer;
}

.css-b62m3t-container{

 background-color: black !important;
}

textarea{
  color: white !important;
}


th,td{

  white-space: nowrap !important;
}

.search{
  width: 25%;
}

@media(max-width:700px)
{
  .search{
    width: 98%; 
    margin-inline:auto !important;
  }
}


.nav-link:hover{
  color: #3b71ca  !important;
}

.form-control{
  background-color: #1a2229 !important;
}

.labeling{
  color: #ff4e00 !important;
}



.detail-item {
  margin-bottom: 15px;
}

.detail-label {
  text-align: left;
  display: block;
  color: #ff4e00 !important;
  font-weight: bold;
}

.detail-value {
  color: white;
  margin-left: 10px;
}
.green
{
  background-color: green !important;
}

.setheight{
  height: 200px !important;
}

.custom-accordion {
  background-color: black !important;
}

.custom-accordion-item {
  background-color: #282c34 !important;
  color: white !important;
}

.logomain{
  width: 150px ;
}
.logomain1{
  width: 230px;
}


/* Footer css */

.privacy{
  background-color: #1a2229 !important ;
  color: white !important;
  margin-top: 100px !important;
}

.buttonclose{
  background-color: #ff4e00 !important;
  color: white !important;
}

.anchor{
  color: #ff4e00 !important;
}


strong{
  color: #ff4e00 !important;

}

.jobcards{
  margin: 30px ;
}

@media (max-width:500px)
{
  
.head{
  padding: 10px;
}

.waveimage{
  height: 540px;
}

.mainheading{
font-size: 30px;
}

.jobcards{
  margin: 15px !important;
}

.nav-tabs{
  justify-content: center !important;
}

.table-responsive
{
  margin: auto !important;
  width: 95% !important;
}
}

.h5{
  color: #ff4e00 ;
}

input{
  color: white !important;
}

textarea{
  color:white !important;
}


@media (max-width:560px) {


  .imagetop {
    width: 100%;
  }

  .head {
    padding: 10px;
  }

  .form55 {

    margin: 0 !important;
    width: 97% !important;
  }

  .waveimage {
    height: 540px;
  }

  .formss {
    margin: 0 !important;
  }

  .removemargin {
    margin: 0 !important;
  }



  .benefits {

    width: 130px;
    height: 90px;
  }


  .imagetopflex {
    flex-wrap: wrap;
    justify-content: center;
  }

  .ease {
    width: 141px;
    height: 101px;
    /* margin-top: -5px; */
  }


  .community {
    width: 123px;
    height: 96px;
  }

}

.imagesinside{
  height: 100px !important;
  ;
}

.imageoutside{
  width: 33.3% !important;
}

.imae{
  height: 300px !important;
}

@media (max-width:500px)
{
  .imageoutside{
    width: 50% !important;
  }
}